*{
  box-sizing: border-box;
}

body{
  color:#393939;
}

h1,h2,h3,h4,h5{
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
